/* body {
    display: flex;
    margin-top: 50px;
    justify-content: center;
    background-color: #FFFCFF;
} */
/* 
button {
    margin-right: 2.5px;
    margin-left: 2.5px;
    width: 75px;
    cursor: pointer;
    box-shadow: 0px 0px 2px gray;
    border: none;
    outline: none;
    padding: 5px;
    border-radius: 5px;
    color: white;
} */

#header {
    color: #414248;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    display: flex;
    align-items: center;
    position: absolute;
    left: 46px;
    top: 214px;
}

#header button {
    background-color: #92a1d1;
}

#monthDisplay {
    width: 145px;
    color: #414248;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
}

#container {
    width: 770px;
}

#weekdays {
    /* width: 100%; */
    margin-top: 35px;
    display: flex;
    text-align: center;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    /* background: #F0F4FE; */
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 0;
}

#weekdays div:first-child {
    border-left: 1px solid #CED7E4;
}

#weekdays div {
    /* width: 100px; */
    height: 26px;
    border-right: 1px solid #CED7E4;
    background: #F0F4FE;
}

#calendar {
    width: 100%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-auto-rows: minmax(100px, auto);
}

.day {
    /* width: 155.57px; */
    height: 171.43px;
    padding: 10px;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
    box-sizing: border-box;
    color: #000000;
    background-color: white;
    box-shadow: 0px 0px 3px #CBD4C2;
    display: flex;
    flex-direction: column;
    position: relative;
}

.day:hover {
    background-color: #F0F4FE;
}

.day ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.day ul li {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #414248;
    letter-spacing: 0.5px;
}

.event-list {
    display: flex;
    justify-content: space-between;
}

.event-desc {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.day ul .event-time {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: #9FAFC7;
}

.event-more {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: #9FAFC7;
}

.day.current {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.5px;
    color: #4E79EE;
    border-bottom: 4px solid #4E79EE;
}

.event {
    font-size: 10px;
    padding: 3px;
    background-color: #58bae4;
    color: white;
    border-radius: 5px;
    max-height: 55px;
    overflow: hidden;
}

.padding {
    cursor: default !important;
    background: #F0F4FE;
}

#newEventModal {
    z-index: 1040;
    background: #ffffff;
    box-shadow: 0px 4px 6px rgba(51, 51, 51, 0.15);
    border-radius: 8px;
    width: 480px;
    top: 5%;
    left: 98%;
    position: absolute;
    font-family: sans-serif;
}

.posLeft {
    right: 98%;
    left: auto !important;
}

.posTop {
    bottom: 5%;
    top: auto !important;
}

.posTopLeft {
    right: 98%;
    left: auto !important;
    bottom: 5%;
    top: auto !important;
}

#newEventModal h2 {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 32px;
    margin: 0;
}

.event-date {
    position: relative;
    padding: 16px 24px;
    border-bottom: 1px solid #CED7E4;
    height: 65px;
}

/* event */

.eventMain {
    padding: 0 18px;
    max-height: calc(470px - 65px);
    overflow: hidden;
    overflow-y: auto;
}

.eventMain::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #F0F4FE;
}

.eventMain::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
}

.eventMain::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #909CAC;
}

.eventData {
    display: flex;
    align-items: center;
    gap: 28px;
    padding: 16px 6px;
    border-bottom: 1px solid #CED7E4;
}

.time {
    max-width: 60px;
    width: 100%;
    display: block;
}

.time p {
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #4264C1;
    margin: 0;
}

.eventDetail {
    max-width: 300px;
    width: 100%;
    display: block;
}

.eventDetail h5 {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #414248;
    margin: 0 0 4px 0;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    white-space: normal;
}

.eventDetail p {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #8C8F9C;
    margin: 0;
}

#eventTitleInput {
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 25px;
    border-radius: 3px;
    outline: none;
    border: none;
    box-shadow: 0px 0px 3px gray;
}

#eventTitleInput.error {
    border: 2px solid red;
}

#backButton {
    width: 7.81px;
    padding-right: 3px;
}

#saveButton,
#closeButton {
    background-color: #92a1d1;
}

#eventText {
    font-size: 14px;
}